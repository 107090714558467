/*---------------------------------------------------------------------------*\
 |  cassette.js                                                              |
\*---------------------------------------------------------------------------*/

console.log(`
 _________
|   ___   |
|  o___o  |
|__/___\\__|

Using Spool at work?  Work at Spool.
https://spool.video/jobs
`);
